<template>
  <div>
    <div  class="bg">
      <div class="title flex">
        <div class="logo">
          LOGO
        </div>
        <div class="flex-1">
          <div class="name">{{data.entName}}</div>
          <div class="desc">
            <span class="sp">{{data.personScope}}</span><span class="sp">{{data.regCap}}{{data.regCapCur}}</span><span>{{data.industryCoName}}</span>
          </div>
          <div>
            <span class="biaoqian" v-for="(li,index) in data1">{{li.label}}</span>
          </div>
        </div>
        <div>
          <i class="shoucang"></i>
          <i class="fenxiang"></i>
        </div>
      </div>
    </div>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import {getEnterpriseMain} from "../../request/moudle";

export default {
  name: "compyindex",
  data(){
    return {
        data:'',
        data1:''
    }
  },
  created() {
    this.loadmain()
  },
  methods:{
    loadmain(){
      this.$api.index.getEnterpriseMain({
        creditCode:'91130983731438081Y'
      }).then((res)=>{
        this.data=res.data.enterpriseInfo
        this.common.addSessionStorage('enterpriseInfo',this.data)
        this.data1=res.data.enterpriseLabelList
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .bg{
    background: url("../../assets/home/compyindex.png") no-repeat;
    .logo{
      width: 161px;
      height: 161px;
      background: #fff;
      border-radius: 8px;
      text-align: center;
      line-height: 161px;
      margin-right: 34px;
    }
    .title{
      margin: 0px auto;
      padding-top: 134px;
      padding-bottom: 50px;
      width: 1200px;
      .name{
        font-size: 22px;
        font-weight: 550;
      }
      .sp{
        margin-right: 20px;
        padding-right: 20px;
        border-right: 1px solid #fff;
      }
      .desc{
        line-height: 65px;
        color: #fff;
      }
      .biaoqian{
        background: linear-gradient(0deg,#0096FF,#0050C9);
        padding: 3px 12px;
        color: #fff;
        border-radius: 4px;
        margin-right: 10px;
      }
    }
  }
</style>